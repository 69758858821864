(function() { 'use strict';

  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 480;
  window.MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.TABLET_WIDTH = 1024;
  window.SMALL_NOTEBOOK_WIDTH = 1240;
  window.NOTEBOOK_WIDTH = 1366;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');
  window.$MENU_BUTTON = $('.header-menu-button');


  // Helpers
  // -------
  window.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i) !== null;
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW.on('resize', function() {
    window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
    window.WINDOW_HEIGHT = $WINDOW.height();
    window.HEADER_HEIGHT = $HEADER.outerHeight();
    window.HEADER_MENU_HEIGHT = $('.header-menu-container').outerHeight();
  });

  window.IS_WIDESCREEN_WIDTH = function() {
    return WINDOW_WIDTH > NOTEBOOK_WIDTH;
  }
  window.IS_NOTEBOOK_WIDTH = function() {
    return ( WINDOW_WIDTH > SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= NOTEBOOK_WIDTH );
  }
  window.IS_SMALL_NOTEBOOK_WIDTH = function() {
    return ( WINDOW_WIDTH > TABLET_WIDTH && WINDOW_WIDTH <= SMALL_NOTEBOOK_WIDTH );
  };
  window.IS_DESKTOP_WIDTH = function() {
    return ( WINDOW_WIDTH >= TABLET_WIDTH );
  }
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < TABLET_WIDTH );
  }
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= MOBILE_WIDTH;
  }
  window.IS_LANDSCAPE_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= LANDSCAPE_MOBILE_WIDTH;
  };
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= SMALL_MOBILE_WIDTH;
  }


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').inputmask('+7 (999) 999-99-99', {
    placeholder: "+7 (___) ___-__-__"
  });

  // Number
  $('.js-number-mask').inputmask('9{*}', {
    greedy: false
  });

  // Float number
  $('.js-float-mask').inputmask('9{*}[\.9{1,2}]', {
    greedy: false
  });


  // Header menu button
  // ------------------
  $MENU_CHECKBOX
    .on('change', function() {
      if( this.checked ) {
        $BODY.addClass('posr ofh');
      } else {
        $BODY.removeClass('posr ofh');
      }
    })
    .prop('checked', false)
    .prop('disabled', false)
    .trigger('change');


  $DOCUMENT
    // Scroll to
    // ---------
    .on('click.js-scroll-to', '.js-scroll-to, .js-menu a', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          menuOffset = (IS_DESKTOP && window.IS_DESKTOP_WIDTH() ? HEADER_MENU_HEIGHT : $MENU_BUTTON.outerHeight()),
          offset = ($lnk.data('scrollOffset') || $elemToScroll.data('scrollOffset') || 0) - menuOffset;

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Footer social links
    // -------------------
    .on('click.footer-social-link', '.footer .footer-socials a', function(e) {
      e.preventDefault();

      var socialWin;

      if( socialWin = window.open($(this).prop('href'), '_blank', 'toolbar=0,location=0,status=0,scrollbars=1,left=50,top=50,width=960,height=600'))
        socialWin.focus();
    })

    // Header menu items
    // -----------------
    .on('click.js-menu', '.js-menu a', function(e) {
      e.preventDefault();

      $MENU_CHECKBOX.prop('checked', false).trigger('change');
    });


  // Window scrolling
  // ----------------
  $WINDOW.on('resize', function() {
    $WINDOW.off('scroll');

    if( IS_DESKTOP && window.IS_DESKTOP_WIDTH() ) {
      $WINDOW.on('scroll', function(e) {
        if( $WINDOW.scrollTop() >= HEADER_HEIGHT - HEADER_MENU_HEIGHT ) {
          $HEADER.css('padding-bottom', HEADER_MENU_HEIGHT).addClass('header-fixed');
        } else {
          $HEADER.css('padding-bottom', 0).removeClass('header-fixed');
        }
      }).trigger('scroll');
    } else {
      $HEADER.css('padding-bottom', 0).removeClass('header-fixed');
    }
  });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    margin: 0,

    infobar: false,
    buttons: [
      'close',
      false,
      false,
      false
    ],

    smallBtn: false,

    protect : true,

    focusElement: '.js-autofocus-inp'
  });

  $('[data-fancybox]:not([data-fancybox-type="iframe"])').fancybox();
  if( IS_DESKTOP )
    $('[data-fancybox][data-fancybox-type="iframe"]').fancybox();


  // Slick sliders
  // -------------
  $('.main-rotator-container').slick({
    autoplay: false,
    autoplaySpeed: 6000,
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    customPaging: function() { return $('<div />'); },
    draggable: false,
    centerMode: true,
    centerPadding: '0px',
    fade: true,
    dots: true,
    slide: '.main-rotator-slide-container',
    appendArrows: '.main-rotator-container .main-rotator-controls',
    appendDots: '.main-rotator-container .main-rotator-dots'
  });


  // Callbacks
  // ---------
  CALLBACKS['reachGoalPhoneOrder'] = function() {
    if( typeof yaCounter47479591 === 'object' )
      yaCounter47479591.reachGoal('phone_order');
  };
  CALLBACKS['reachGoalCalculator'] = function() {
    if( typeof yaCounter47479591 === 'object' )
      yaCounter47479591.reachGoal('calculator');
  };
  CALLBACKS['reachGoalCallbackDiscount'] = function() {
    if( typeof yaCounter47479591 === 'object' )
      yaCounter47479591.reachGoal('callback_discount');
  };


  // Triggers
  // --------
  $WINDOW.trigger('resize');

})();
